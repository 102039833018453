'use client';
// prettier-ignore-start
import '~/modules/utilities/load_client_config'; // must be first import
// prettier-ignore-start
import '~/modules/clients/load_client_logger'; // must be second import!
import '~/util/analytics';
import '../styles/global.css';
// prettier-ignore-end
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useRouter } from 'next/compat/router';
import { Footer } from '~/components/Footer';
import { Meta } from '~/components/Meta';
import { Navbar } from '~/components/Navbar';
import { RequireSupabaseAuth } from '~/components/RequireAuth';
import { supabase } from '~/lib/supabase';
import { IS_DEVELOPMENT } from '~/modules/config';
import { featureFlagsStore } from '~/modules/stores/feature_flags_store';
import { logger } from '~/modules/utilities/cross_env_utils';

const queryClient = new QueryClient();

if (typeof window !== 'undefined') {
  logger.log(
    `${bundledConfig.NAME} v${bundledConfig.VERSION} built on ${new Date(
      bundledConfig.BUILD_TIMESTAMP
    ).toLocaleString()}${IS_DEVELOPMENT ? ' (Development)' : ''}`
  );
  featureFlagsStore.getFeatureFlags({
    setValues: window.stores?.FEATURE_FLAGS
  });
}

export default function App({ Component, pageProps }) {
  const router = useRouter();
  return (
    <Meta>
      <QueryClientProvider client={queryClient}>
        <SessionContextProvider supabaseClient={supabase} initialSession={pageProps.initialSession}>
          <RequireSupabaseAuth showLoading={false}>
            <Navbar bgColor="bg-white" />
          </RequireSupabaseAuth>
          {router.pathname === '/auth/[auth_type]' && router.query.auth_type === 'signin' ? (
            <Component {...pageProps} />
          ) : (
            <RequireSupabaseAuth showLoading={false}>
              <Component {...pageProps} />
            </RequireSupabaseAuth>
          )}
          <RequireSupabaseAuth showLoading={false}>
            <Footer size="md" bgColor="bg-white" bgImage="" bgImageOpacity={1} textColor="" sticky={true} />
          </RequireSupabaseAuth>
        </SessionContextProvider>
      </QueryClientProvider>
    </Meta>
  );
}

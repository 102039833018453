import { useSessionContext } from '@supabase/auth-helpers-react';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { usePushRoute } from '~/util/routes';

export function RequireSupabaseAuth({ children, showLoading = true }) {
  const pushRoute = usePushRoute();
  const sessionContext = useSessionContext();

  if (!sessionContext.isLoading && !sessionContext.session) {
    pushRoute('/auth/[auth_type]', { auth_type: 'signin' });
    return null;
  }

  if (sessionContext.isLoading) {
    return showLoading ? (
      <div className="flex flex-col container py-96 items-center">
        <div className="flex">
          <LoadingSpinner />
          <span className="pb-2 pl-2">Authenticating, please wait...</span>
        </div>
      </div>
    ) : null;
  }

  if (!sessionContext.session) {
    return null;
  }

  return children;
}

export const classes = {
  navLink: 'font-semibold inline-flex items-center space-x-1 h-8 px-4 group-hover:text-blue-500 py-6',
  navLinkIcon: 'opacity-50 transform transition duration-200 ease-out group-hover:rotate-180 inline-block w-4 h-4',
  dropdown: {
    base: 'absolute top-19 pt-1 z-10 invisible group-hover:visible transform transition duration-800 ease-in opacity-0 group-hover:opacity-100',
    left: 'right-0',
    center: 'left-1/2 -translate-x-1/2',
    right: 'left-0',
    normal: 'w-48',
    large: 'w-96',
    inner: 'bg-white shadow-xl ring-1 ring-black ring-opacity-5 rounded-lg overflow-hidden',
    title: 'text-xs uppercase font-semibold tracking-wider text-blue-800 mb-5',
    link: 'text-gray-600 hover:text-blue-600 font-medium text-sm flex items-center space-x-2',
    icon: 'opacity-25 inline-block w-5 h-5',
    feature: 'p-3 rounded-xl flex items-center space-x-4 text-gray-600 font-medium text-sm',
    featureName: 'font-semibold mb-1',
    featureDescription: 'opacity-75'
  },
  accountDropdown: {
    base: 'absolute right-0 origin-top-right mt-2 w-48 z-10',
    inner: 'bg-white ring-1 ring-black ring-opacity-5 rounded divide-y divide-gray-100 shadow-xl rounded',
    link: 'flex items-center space-x-2 rounded py-2 px-3 text-sm font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-700',
    linkActive: 'text-gray-700 bg-gray-100',
    linkInactive:
      'text-gray-600 hover:bg-gray-100 hover:text-gray-700 focus:outline-none focus:bg-gray-100 focus:text-gray-700',
    icon: 'opacity-50 inline-block w-5 h-5'
  }
};

import { AxiosError, AxiosResponse } from 'axios';
import { API_VERSION, SCHEMA_VERSION } from '~/modules/config';

export enum PaginatedLoadingState {
  UNINITIALIZED,
  LOADING,
  LOADING_MORE,
  LOADED
}

export interface PaginatedResponse<T = unknown> {
  results: T[];
  totalResults: number;
  currentPage: number;
  totalPages: number;
  hasNextPage: boolean;
}

export interface ApiResponse {
  error?: string | string[];
  message?: unknown;
}

export function withVersionHeaders(apiVersion = API_VERSION, schemaVersion = SCHEMA_VERSION) {
  return {
    headers: {
      Version: apiVersion,
      SchemaVersion: schemaVersion
    }
  };
}

export function selectData<T = any, D = any>(response: AxiosResponse<T, D>) {
  return response.data;
}

// TODO: Improve these functions
export function handleNetworkWarning(error: AxiosError<ApiResponse>) {
  if (error.response) {
    const code = error.response.status;
    console.warn(`SERVER [${code}]: ${error.response.data.message}`);
    // TODO: Display a warning toast (maybe?)
  } else {
    console.warn(`SERVER: ${error.message}`);
    // TODO: Display a warning toast (maybe?)
  }
}

// TODO: Improve these functions
export function handleNetworkError(error: AxiosError<ApiResponse>) {
  if (error.response) {
    const code = error.response.status;
    console.error(`SERVER [${code}]: ${(error.response.data && error.response.data.message) || undefined}`);
    return;
  }
  console.error(`ERROR: ${JSON.stringify(error)}`);
}

// https://stackoverflow.com/a/70684512
export function serializeAxiosError(error: any) {
  if (!error) {
    return { status: 500, message: 'Something went wrong' };
  }
  const { request, response } = error;
  let result;
  if (response) {
    result = {
      status: response.status,
      message: typeof response.data === 'object' ? response.data.message || response.data : response.data,
      url: response.config?.url,
      method: response.config?.method
    };
  } else if (request) {
    // request sent but no response received
    result = {
      message: 'Request timed out',
      status: 503
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    result = { status: 500, message: error.message || 'Something went wrong' };
  }
  return result;
}

export function emptyObject<T = AnyObject>() {
  return Object.create(null) as T;
}

export function isObject(object: unknown): object is AnyObject {
  return typeof object === 'object' && !!object;
}

export function isObjectEmpty(object: AnyObject) {
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      return false;
    }
  }
  return true;
}

function isInArray(key: string, matches: string[]) {
  return matches.includes(key);
}

function stringsMatch(key: string, match: string) {
  return key === match;
}

export function keyExists(object: unknown, keys: string | string[]) {
  if (!isObject(object)) {
    return false;
  }

  if (Array.isArray(object)) {
    for (const item of object) {
      if (keyExists(item, keys)) {
        return true;
      }
    }
    return false;
  }

  const matchFn = Array.isArray(keys) ? isInArray : stringsMatch;

  for (const key of Object.keys(object)) {
    // We already did type checking in the line above, adding more checks here would cause performance degradation just to appease TS compiler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (matchFn(key, keys as any) || keyExists((object as any)[key], keys)) {
      return true;
    }
  }

  return false;
}

/**
 * Clones an object and returns a new one with its keys sorted.
 *
 * @param {Record<string, unknown>} object
 * @returns {Record<string, unknown>}
 */
export function sortObjectKeys(object: AnyObject, compareFn?: (a: string, b: string) => number): AnyObject {
  return Object.keys(object)
    .sort(compareFn)
    .reduce((obj: AnyObject, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
}

// IMPORTANT: This deepEquals function is only appropriate to use on JSON objects (without Date or RegExp values)
// Largely based on the dequal package by lukeed, minus Date and RegExp capabilities: https://www.npmjs.com/package/dequal
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEquals(foo: any, bar: any): boolean {
  let ctor, len: number;
  if (foo === bar) {
    return true;
  }

  if (foo && bar && (ctor = foo.constructor) === bar.constructor) {
    if (ctor === Array) {
      if ((len = foo.length) === bar.length) {
        // eslint-disable-next-line no-empty
        while (len-- && deepEquals(foo[len], bar[len])) {}
      }
      return len === -1;
    }

    if (!ctor || typeof foo === 'object') {
      len = 0;
      // eslint-disable-next-line guard-for-in
      for (ctor in foo) {
        if (
          Object.prototype.hasOwnProperty.call(foo, ctor) &&
          ++len &&
          !Object.prototype.hasOwnProperty.call(bar, ctor)
        ) {
          return false;
        }
        if (!(ctor in bar) || !deepEquals(foo[ctor], bar[ctor])) {
          return false;
        }
      }
      return Object.keys(bar).length === len;
    }
  }

  // eslint-disable-next-line no-self-compare
  return foo !== foo && bar !== bar;
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze#what_is_shallow_freeze
export function deepFreeze<T extends AnyObject>(object: T) {
  const propNames = Object.getOwnPropertyNames(object);

  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value as AnyObject);
    }
  }

  return Object.freeze(object);
}

// Equivalent to klona/json: https://github.com/lukeed/klona/blob/master/src/json.js
export function deepClone<T = any>(source: T): T {
  var k: any, out: any, tmp: any;

  if (Array.isArray(source)) {
    out = Array((k = source.length));
    while (k--) out[k] = (tmp = source[k]) && typeof tmp === 'object' ? deepClone(tmp) : tmp;
    return out;
  }

  if (Object.prototype.toString.call(source) === '[object Object]') {
    out = {}; // null
    for (k in source) {
      if (k === '__proto__') {
        Object.defineProperty(out, k, {
          value: deepClone((source as any)[k]),
          configurable: true,
          enumerable: true,
          writable: true
        });
      } else {
        out[k] = (tmp = (source as any)[k]) && typeof tmp === 'object' ? deepClone(tmp) : tmp;
      }
    }
    return out as unknown as T;
  }

  return source;
}

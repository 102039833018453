import { Bars3Icon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/compat/router';
import Link from 'next/link';
import { useState } from 'react';
import { Button } from '~/components/Button';
import { RequireSupabaseAuth } from '~/components/RequireAuth';
import Section from '~/components/Section';
import { classes } from '~/lib/tailwind';

export function Navbar(props) {
  const router = useRouter();
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <RequireSupabaseAuth>
      <Section bgColor={props.bgColor} className="z-20">
        <div className="container py-4">
          <div className="flex justify-between">
            <Link
              href="/"
              className="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-blue-600 hover:text-blue-400"
            >
              <img src="/images/logos/black_logo.png" alt="Stock Unlock Logo" className="w-[360px] h-[61px]" />
            </Link>
            <div className="flex items-center ml-auto space-x-1 lg:space-x-4">
              <ul className="hidden lg:flex items-center ml-auto">
                {/* <li className="relative group">
                  <span className={`${classes.navLink}`}>
                    <span>Cannabis Stock Tiers</span>
                    <ChevronDownIcon className={classes.navLinkIcon} />
                  </span>
                  <div className={`${classes.dropdown.base} ${classes.dropdown.large} ${classes.dropdown.center}`}>
                    <div className={`${classes.dropdown.inner}`}>
                      <div className="p-3">
                        <div className="px-3 pt-3">
                          <h4 className={`${classes.dropdown.title}`}>Cannabis Stock Tiers</h4>
                        </div>
                        <nav className="flex flex-col space-y-1">
                          <Link href={{ pathname: '/rankings/stocks/cannabis', query: { ...router.query, tier: '1' } }}>
                            <a className={`hover:text-blue-600 hover:bg-blue-50 ${classes.dropdown.feature}`}>
                              <div>
                                <h5 className={`${classes.dropdown.featureName}`}>Tier 1 (Large)</h5>
                                <p className={`${classes.dropdown.featureDescription}`}>
                                  The largest companies by market capitalization, these offer the best liquidity and
                                  benefit from economies of scale.
                                </p>
                              </div>
                            </a>
                          </Link>
                          <Link href={{ pathname: '/rankings/stocks/cannabis', query: { ...router.query, tier: '2' } }}>
                            <a className={`hover:text-emerald-600 hover:bg-emerald-50 ${classes.dropdown.feature}`}>
                              <div>
                                <h5 className={`${classes.dropdown.featureName}`}>Tier 2 (Medium)</h5>
                                <p className={`${classes.dropdown.featureDescription}`}>
                                  Companies in the middle of the pack, less risky than smaller companies, but not as
                                  established as the larger companies.
                                </p>
                              </div>
                            </a>
                          </Link>
                          <Link href={{ pathname: '/rankings/stocks/cannabis', query: { ...router.query, tier: '3' } }}>
                            <a className={`hover:text-orange-600 hover:bg-orange-50 ${classes.dropdown.feature}`}>
                              <div>
                                <h5 className={`${classes.dropdown.featureName}`}>Tier 3 (Small)</h5>
                                <p className={`${classes.dropdown.featureDescription}`}>
                                  The smallest, and riskiest stocks. Smaller companies offer less liquidity, so expect
                                  longer holding periods and higher likelyhood of capitall loss by investing in these
                                  stocks.
                                </p>
                              </div>
                            </a>
                          </Link>
                        </nav>
                      </div>
                    </div>
                  </div>
                </li> */}
                <li className="relative group">
                  <Link href="/about" className={`${classes.navLink}`}>
                    <span>About</span>
                  </Link>
                </li>
              </ul>

              <div className="flex lg:hidden items-center justify-center">
                <Button
                  variant="simple"
                  size="sm"
                  onClick={() => setMobileNavOpen(!mobileNavOpen)}
                  endIcon={<Bars3Icon className="inline-block w-5 h-5" />}
                />
              </div>
            </div>
          </div>
          <div className={'lg:hidden' + (!mobileNavOpen ? ' hidden' : '')}>
            <div className="grid grid-cols-1 sm:grid-cols-3 border rounded-xl my-5">
              {/* <div className="col-span-2 p-3 space-y-6">
                <div className="px-3 pt-3">
                  <h4 className={`${classes.dropdown.title}`}>Featured Products</h4>
                </div>
                <nav className="flex flex-col space-y-1">
                  <Link href="/">
                    <a className={`hover:text-blue-600 hover:bg-blue-50 ${classes.dropdown.feature}`}>
                      <div>
                        <FeatureIcon color="blue">
                          <CodeBracketIcon />
                        </FeatureIcon>
                      </div>
                      <div>
                        <h5 className={`${classes.dropdown.featureName}`}>HTML Editor</h5>
                        <p className={`${classes.dropdown.featureDescription}`}>
                          Write and manipulate your markup directly in your browser
                        </p>
                      </div>
                    </a>
                  </Link>
                  <Link href="/">
                    <a className={`hover:text-emerald-600 hover:bg-emerald-50 ${classes.dropdown.feature}`}>
                      <div>
                        <FeatureIcon color="emerald">
                          <SwatchIcon />
                        </FeatureIcon>
                      </div>
                      <div>
                        <h5 className={`${classes.dropdown.featureName}`}>CSS Editor</h5>
                        <p className={`${classes.dropdown.featureDescription}`}>
                          Style your markup code with smart tools supporting Sass
                        </p>
                      </div>
                    </a>
                  </Link>
                  <Link href="/">
                    <a className={`hover:text-orange-600 hover:bg-orange-50 ${classes.dropdown.feature}`}>
                      <div>
                        <FeatureIcon color="orange">
                          <PuzzlePieceIcon />
                        </FeatureIcon>
                      </div>
                      <div>
                        <h5 className={`${classes.dropdown.featureName}`}>Web Page Builder</h5>
                        <p className={`${classes.dropdown.featureDescription}`}>
                          Explore all available components and build your website
                        </p>
                      </div>
                    </a>
                  </Link>
                </nav>
              </div> */}
              <div className="p-6 space-y-6">
                {/* <h4 className={`${classes.dropdown.title}`}>Learn</h4>
                <nav className="flex flex-col space-y-3">
                  <Link href="/">
                    <a className={`${classes.dropdown.link}`}>Resource Center</a>
                  </Link>
                  <Link href="/">
                    <a className={`${classes.dropdown.link}`}>Developer</a>
                  </Link>
                  <Link href="/">
                    <a className={`${classes.dropdown.link}`}>User Guides</a>
                  </Link>
                </nav> */}
                {/* <h4 className={`${classes.dropdown.title}`}>More</h4> */}
                <nav className="flex flex-col space-y-3">
                  <Link href="/about" className={`${classes.dropdown.link}`}>
                    About
                  </Link>
                  {/*<Link href="/pricing">
                    <a className={`${classes.dropdown.link}`}>Pricing</a>
                  </Link>

                   {!auth.user && (
                  <Link href={{ pathname: '/auth/[auth_type]', query: { auth_type: 'signin' } }}>
                    <a className={`${classes.dropdown.link}`}>Sign in</a>
                  </Link>
                )} */}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </RequireSupabaseAuth>
  );
}

import { buildBundledConfig } from './build_bundled_config';

if (!globalThis.clientConfig) {
  // When running with next dev, we need to create the config since it's not injected by the server.
  // Accessing `process.env.*` here is required to inject the env vars from next cli.
  globalThis.clientConfig = buildBundledConfig(
    {
      NODE_ENV: process.env.NODE_ENV,
      NEXT_PUBLIC_WS_LOGGER_URL: process.env.NEXT_PUBLIC_WS_LOGGER_URL,
      NEXT_PUBLIC_WS_LOGGER_SOURCE: process.env.NEXT_PUBLIC_WS_LOGGER_SOURCE,
      NEXT_PUBLIC_FEATURE_FLAG_URL: process.env.NEXT_PUBLIC_FEATURE_FLAG_URL,
      NEXT_PUBLIC_FEATURE_FLAG_CLIENT_KEY: process.env.NEXT_PUBLIC_FEATURE_FLAG_CLIENT_KEY,
      NEXT_PUBLIC_FEATURE_FLAG_APP_NAME: process.env.NEXT_PUBLIC_FEATURE_FLAG_APP_NAME,
      NEXT_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL: process.env.NEXT_PUBLIC_FEATURE_FLAG_REFRESH_INTERVAL,
      NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
      NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
      NEXT_PUBLIC_ENABLE_AXIOS_CREDENTIALS: process.env.NEXT_PUBLIC_ENABLE_AXIOS_CREDENTIALS,
      NEXT_PUBLIC_URL: process.env.NEXT_PUBLIC_URL,
      NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
      NEXT_PUBLIC_SHEETS_ENDPOINT: process.env.NEXT_PUBLIC_SHEETS_ENDPOINT,
      NEXT_PUBLIC_SHEETS_TAB_ID: process.env.NEXT_PUBLIC_SHEETS_TAB_ID,
      NEXT_PUBLIC_STRIPE_PRICE_BUSINESS: process.env.NEXT_PUBLIC_STRIPE_PRICE_BUSINESS,
      NEXT_PUBLIC_STRIPE_PRICE_PRO: process.env.NEXT_PUBLIC_STRIPE_PRICE_PRO,
      NEXT_PUBLIC_STRIPE_PRICE_STARTER: process.env.NEXT_PUBLIC_STRIPE_PRICE_STARTER,
      NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY
    },
    { PORT: bundledConfig.PORT, VERSION: bundledConfig.VERSION }
  );
}

import { WS_LOGGER_SOURCE, WS_LOGGER_URL } from '~/modules/config';
import { runIfDev } from '~/modules/utilities/cross_env_utils';

export type LoggerOptions = {
  wssEndpoint: string;
  source?: string;
  prefix?: string;
};

export enum LoggerConnectionStatus {
  Connecting,
  Connected,
  Closed,
}

export class ClientLogger {
  prefix: string;
  source: string | undefined;
  logSocket: WebSocket | undefined;
  messageQueue: string[] | undefined;
  status: LoggerConnectionStatus = LoggerConnectionStatus.Closed;
  LOGLEVEL = {
    ERROR: 'error',
    WARN: 'warn',
    LOG: 'log',
    DEBUG: 'debug',
    VERBOSE: 'verbose',
    METRIC: 'metric',
  };

  constructor({ wssEndpoint = WS_LOGGER_URL, source = WS_LOGGER_SOURCE, prefix = '' } = {}) {
    this.prefix = prefix;
    this.source = source;

    if (typeof WebSocket === 'undefined') {
      return;
    }
    if (!wssEndpoint) {
      runIfDev(() => console.warn("'wssEndpoint' missing. Logger will print to the console only."));
      return;
    }
    if (!source) {
      runIfDev(() => console.warn("'source' missing. Logger will print to the console only."));
      return;
    }
    if (wssEndpoint.endsWith('/')) {
      runIfDev(() =>
        console.warn(`'${wssEndpoint}' is invalid due to a trailing slash. Logger will print to the console only.`),
      );
      return;
    }

    try {
      this.messageQueue = [];
      this.status = LoggerConnectionStatus.Connecting;
      this.logSocket = new WebSocket(wssEndpoint);
      this.logSocket.onopen = () => {
        this.status = LoggerConnectionStatus.Connected;
        // Flush queued messages
        for (const message of this.messageQueue!) {
          this.logSocket!.send(message);
        }
        this.messageQueue = undefined;
      };
      this.logSocket.onclose = () => {
        this.messageQueue = undefined;
        this.status = LoggerConnectionStatus.Closed;
        console.warn('Connection Closed');
      };
      this.logSocket.onerror = () => {
        this.messageQueue = undefined;
        this.status = LoggerConnectionStatus.Closed;
        console.error('Connection Error');
      };
    } catch (exception) {
      this.logSocket = undefined;
      this.messageQueue = undefined;
      this.status = LoggerConnectionStatus.Closed;
      console.error(`An error occurred while initializing the logger: ${exception}`);
    }
  }
  error(...args) {
    this.send(this.LOGLEVEL.ERROR, args);
  }
  warn(...args) {
    this.send(this.LOGLEVEL.WARN, args);
  }
  log(...args) {
    this.send(this.LOGLEVEL.LOG, args);
  }
  debug(...args) {
    this.send(this.LOGLEVEL.DEBUG, args);
  }
  verbose(...args) {
    this.send(this.LOGLEVEL.VERBOSE, args);
  }
  metric(metric, identifier, value) {
    this.send(this.LOGLEVEL.METRIC, { metric, identifier, value }, 'metric');
  }
  send(level, args, event = 'log') {
    let numArgs = args.length;

    if (numArgs > 0 && typeof args[0] === 'string') {
      // If the user sends a context string as the second parameter, append it to the first parameter
      if (numArgs === 2 && typeof args[1] === 'string') {
        args[0] = `${this.prefix ? `[${this.prefix}]` : ''}[${args[1]}] ${args[0]}`;
        args.length = numArgs = 1;
      }
      // Otherwise if they provide a prefix, append it to the first parameter
      else if (this.prefix) {
        args[0] = `[${this.prefix}] ${args[0]}`;
      }
    }

    // There is no 'verbose' or 'metric' logging on browser consoles, so convert those into debug logs
    const operation = level in console ? level : 'debug';
    console[operation](...args);

    if (this.status === LoggerConnectionStatus.Closed) {
      return;
    }

    const logMessage = JSON.stringify({
      event,
      data: {
        level,
        time: Date.now(),
        source: this.source,
        message: numArgs === 1 ? args[0] : args,
      },
    });

    if (this.status === LoggerConnectionStatus.Connecting) {
      this.messageQueue!.push(logMessage);
      return;
    }

    this.logSocket!.send(logMessage);
  }
}
